@import url('https://fonts.googleapis.com/css?family=Hind|Karla|Raleway|Quicksand|Righteous|Inconsolata&display=swap');
@import url('App-Mobile.css');

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Hind', sans-serif !important;
  color: #212121 !important;
  background-color: #FFCDD2 !important;
  /*background: url("Images/apple-background-desk.jpg") no-repeat right fixed;*/
  background-size: 74vw 100vh;
}

h1, h2, h3, h4 {
  color: #673AB7 !important;
}

h1 {
  /*font-family: 'Karla', sans-serif;*/
  font-family: 'BenchNine', sans-serif;
  font-size: 2.1rem !important;
  font-weight: normal;
  margin: 0 3% 3% 0 !important;
}

h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 1.3rem !important;
  margin: 3% 5% 5% 0 !important;
}

.App-logo {
  align-items: right;
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header */

.header {
  display: flex;
  width: 100%;
  color: #FFF;
  font-size: calc(10px + 2vmin);
  margin: 0 auto 3% auto;
  padding: 1%;
  background-image: url("Images/purple-neon-computer.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 500px;
}

.header-title {
  display: flex;
  align-items: center;
  align-content: space-between;
  width: 100%;
  height: min-content;
}

.header-title h1 {
  color: #FFF !important;
  font-family: 'Righteous', cursive;
  font-size: 2.2rem !important;
  text-shadow: 1.5px 1.5px #673AB7;
  letter-spacing: 0.2em;
  margin: 0 1% !important;
  padding: 1%;
}

.logo-image {
  max-width: 10%;
  height: 50px;
  margin: auto 0;
  padding: 0;
  /*border: 3px double #212121;*/
  border: 3px double #FFF;
  border-radius: 50%;
}

.sub-header-banner {
  width: 100%;
  height: min-content;
  text-align: center;
}

.sub-header-banner h1 {
  /*font-family: 'Quicksand', sans-serif !important;
  font-family: 'Inconsolata', monospace;*/
  font-family: 'Righteous', cursive;
  font-size: 2.2rem;
  text-shadow: 1.5px 1.5px #FFF;
  letter-spacing: 0.2em;
}

/* Navigation */

.navigation {
  display: flex;
  font-size: 1.3rem !important;
  text-align: right;
  width: 100%;
  height: min-content;
  margin: 0 auto;
  /*position: fixed;*/
}

.navigation a {
  text-decoration: none;
  margin: 0 auto;
  padding: 3%;
}

.navigation a:active, .navigation a.current {
  text-decoration: underline;
}

.navigation a:hover {
  text-decoration: none;
  font-weight: bold;
}

.navigation a:visited {
  /*color: #FFF;*/
  color: #212121;
}

.sub-navigation {
  display: inline-block;
  width: 100%;
}

/* Main Content */

.App-body {
  display: flex;
  flex: 1 0 auto;
}

.main-content {
  width: 100%;
  height: min-content;
  flex: 0 0 auto;
  background-color: #FFF;
  opacity: 0.9 !important;
  padding: 5%;
  /*border: 5px double #212121;
  border-radius: 20px;
  overflow-y: scroll;*/
  /* FireFox */
  scrollbar-color: #673AB7 !important;
  scrollbar-width: 0.5vw;
  /* IE/Edge */
  scrollbar-face-color: #673AB7 !important;
}

.home-section {
  display: flex;
  align-items: center; 
  /* margin: 3%; */
  padding: 2%;
}

.home-description {
  width: 60%;
  background-color: #FFCDD2;
  margin: auto 5%;
  padding: 5%;
}

.home-section img {
  border: 5px solid #FFCDD2;
}

.home-section:nth-child(2n) {
  flex-direction: row-reverse;
}

/* Scrollbar - Chrome and Safari */

.main-content::-webkit-scrollbar {
  width: 0.5vw;
}

.main-content::-webkit-scrollbar-track {
}

.main-content::-webkit-scrollbar-thumb {
  background-color: #673AB7;
}

.main-content::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 3%;
}

.main-content::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 3%;
}

/* Breadcrumbs */

.breadcrumbs a, .breadcrumbs a:hover, .breadcrumbs a:visited {
  color: #673AB7;
}

/* Page Under Construction */

.pageUnderConstruction h2 {
  text-align: center;
}

.pageUnderConstruction img {
  display: block;
  width: 30%;
  margin: 0 auto;
}

/* Home */

.home {
  font-family: 'Righteous', cursive;
  text-align: center;
}

.home img {
  display: block;
  width: 40%;
  margin: 2em auto;

}

/* About */

.page-title {
  font-family: 'Righteous', cursive;
  font-size: 2.3rem;
}

.tagline {
  font-size: 1.7rem !important;
}

.about-description {
  font-size: 1.6rem;
  width: 70%;
  margin-bottom: 10%;
}

.about-description p {
  margin: 3rem auto;
}

.bio-image {
  width: 30%;
  float: right;
  margin: auto 2%;
}

.resume {
  margin: 5% 0;
}

.resume a, .resume a:visited, .resume a:hover {
  color: #212121;
  text-transform: uppercase;
  font-weight: bold;
}

.resume img {
  width: 5%;
}

.credits-container {
  display: flex;
}

.credits {
  /*width: 60%;*/
  margin: 0 3%;
  padding: 2%;
}

.credits-btn {
  width: 25%;
}

.credits a {
  color: #9575CD;
}

.credits a:hover, .credits a:visited {
  color: #673AB7;
}

.credits-enter {
  display: block !important;
  opacity: 0;
  transform: scale(0.9);
}
.credits-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.credits-exit {
  opacity: 1;
}
.credits-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* Portfolio */

.portfolio {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.portfolio .header-link {
  display: flex;
  flex: 0 0 30%;
}

.portfolio .header-link, .portfolio .header-link:hover, .portfolio .header-link:visited {
  text-decoration: none;
}

.portfolio .header-tile {
  color: #FFF !important;
  background-color: #212121;
  text-align: right;
  vertical-align: text-bottom;
  margin: 5%;
  padding: 1rem;
  border-radius: 10px;
}

.header-tile img {
  width: 20%;
}

.portfolio .header-title {
  font-size: 1.8rem;
  font-weight: bold;
}

.portfolio .header-description {
  font-size: 1.2rem;
}

.project-grid {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.project-grid .project-link {
  flex: 0 1 45%;
  align-self: flex-end;
  margin: 1%;
}

.project-grid .project-link, .project-grid .project-link:hover, .project-grid .project-link:visited {
  text-decoration: none;
}

.project-grid .project-tile {
  display: flex;
  flex-direction: column;
  color: #FFF !important;
}

.project-tile img {
  width: 100%;
  height: 100%;
}

.project-image-container {
  background-color: #fff;
  /*margin-bottom: 10%;*/
}

.project-grid .project-details {
  width: 100%;
  background-color: #212121;
  margin: 0;
  padding: 5%;   
}

.project-grid .project-title {
  font-weight: bold;
}

.project-grid .project-category {
  font-size: .9em;
  text-transform: lowercase;
  font-style: italic;
}

/* Contact Page */

.contact-content {
  display: flex;
  flex-direction: column;
}

.social-media-container {
  flex: 0 1 50%;
  margin-right: 2%;
}

.social-media-container h1, .social-media-container h2 {
  text-align: center;
}

/* Social Media */

.icon-carousel {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
}

.sm-icon-container {
  flex: 0 1 35%;
  text-align: center;
  margin: 0 auto;
}

.sm-icon-container a {
  color: #9575CD;
}

.sm-icon-container a:active, .sm-icon-container a:hover, .sm-icon-container a:visited  {
  color: #9575CD;
  font-weight: bold;
}

.sm-icon-container a, .sm-icon-container a:hover, .sm-icon-container a:visited {
  text-decoration: none;
}

.sm-icon-container img {
  margin: 0 auto;
  width: 40%;
}

/* Contact Form */

.contact-form {
  width: 70%;
  color: #212121;
  margin: 5% auto;
  padding: 2%;
}

.contact-form .form-group {
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.contact-form input {
  width: 90%;
  height: 3rem;
  padding: 1%;
}

.contact-form textarea {
  padding: 1%;
}

.contact-form label {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 2% 2% 2% 0;
}

.contact-form button {
  display: block;
  margin: 0 auto;
  background-color: #9575CD;
}

input.form-control:focus, textarea.form-control:focus {
  border-color: #9575CD;
  box-shadow: 0 0 0 0.2rem rgba(149, 117, 205, 1);
}

/* Sidebar */

.side-content {
  display: none;
  height: fit-content;
  text-align: center;
  color: #FFF;
  background-color: #212121;
  opacity: 90%;
  margin-left: 10%;
  padding: 5%;
  border: 5px double #FFF;
  border-radius: 10px;
}

/* Footer */

.footer {
  flex-shrink: 0 !important; 
  color: #FFF;
  background-color: #212121;
  padding: 0.5%;
}

.footer p {
  text-align: center;
  margin: 1rem auto;
}

/* Misc. */

.alert-primary {
  background-color: #FFCDD2 !important;
  border-color: #FFCDD2 !important;
}

a.black-link {
  color: #212121 !important;
}

a.white-link {
  color: #FFF !important;
}


