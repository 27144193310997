/* Small Screens */

@media (max-width: 1550px) {
	.sub-header-banner {
		text-align: center;
	}

}

@media (max-width: 1200px) {
	body {
		background-image: none !important;
	}

	.header {
		flex-direction: column;
		align-items: center;
	}

	.logo-image {
		width: 50px !important;
	}

	.navigation {
		width: 100% !important;
		text-align: center;
	}

	.sub-header-banner {
		width: 100% !important;
		font-size: 2rem;
		text-align: center !important;

	}
}

@media (max-width: 1000px) {
	.logo-image {
		width: 50px !important;
	}

	.main-content  {
		width: 50% !important;
		height: unset !important;
	}

	.side-content {
		width: 25% !important;
		margin-left: 2% !important;
	}
}

/* Mobile/Tablet < 900px */

@media (max-width: 900px) {
	.App-body {
		flex-direction: column;
		align-items: center;
	}

	.header {
		text-align: center;
		align-items: center;
	}

	.header-title {
		flex-direction: column !important;
	}

	.logo-image {
		max-width: fit-content !important;
	}

	.navigation, .header-title, .sub-header-banner {
		width: 100% !important;
		text-align: center !important;
		margin: 1% !important;
	}

	.main-content, .side-content {
		width: 90% !important;
		margin-bottom: 3%;
	}

	.side-content {
		margin-left: 0 !important;
		padding: 2% !important;
	}

	.side-content p {
		margin: 0 !important;
	}
}

/* Mobile/Tablet < 600px */

@media (max-width: 600px) {
	.header {
		height: 200px !important;
	}

	.sub-header-banner h1 {
		font-size: 1rem !important;
    	text-align: center;
	}

	.home-section {
		flex-direction: column-reverse !important;
	}

	.home-section img {
		width: 90% !important;
	}

	.home-description {
		width: auto !important;
	}

	.about-description {
		font-size: 1rem !important;
		width: 100% !important;
	}

	.resume img {
    	width: 10% !important;
	}

	.credits-btn {
		width: 100% !important;
	}

	.credits-container {
		flex-direction: column !important;
	}

	.portfolio {
		flex-wrap: wrap !important;
	}

	.page-title {
		font-size: 2rem !important;
		text-align: center;
	}

	.tagline {
		text-align: center;
	}

	.header-link {
		flex: 1 0 100% !important;
	}
}